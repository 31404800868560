// We use this SCSS file for generating custom spacing classes when compiling our theme CSS.
// We decided to use our own custom spacing schema (with each spacing class increasing by 8px, or .5rem)
// instead of using the Bootstrap/Around defaults. We also extend the sizing classes (which typically stop at 5)
// up to 8, allowing us for a range of sizing from 8px/.5rem (at 1) to 64px/4rem (at 8).

// When the CSS is compiled, these new classes should show up at the bottom of the file — they should then override
// the Bootstrap standard classes (which will still be found higher up in the file). These classes appear at the bottom of
// the file because we're importing this stylesheet in the `_user` stylesheet, which is in turn imported
// last in the `theme.scss` stylesheet, which is what ultimately compiles to the `theme.css` output file.
// If a class is defined multiple times in a CSS file, the system will use the LAST definition of that class when applying CSS.
$custom_spacer: 1;

$zero: 0;
$one: unquote( ( $custom_spacer * 0.5  ) + 'rem' );
$two: unquote( ( $custom_spacer * 1  ) + 'rem' );
$three: unquote( ( $custom_spacer * 1.5 ) + 'rem' );
$four: unquote( ( $custom_spacer * 2 ) + 'rem' );
$five: unquote( ( $custom_spacer * 2.5 ) + 'rem' );
$six: unquote( ( $custom_spacer * 3 ) + 'rem' );
$seven: unquote( ( $custom_spacer * 3.5 ) + 'rem' );
$eight: unquote( ( $custom_spacer * 4 ) + 'rem' );
$nine: unquote( ( $custom_spacer * 4.5 ) + 'rem' );
$ten: unquote( ( $custom_spacer * 5 ) + 'rem' );
$eleven: unquote( ( $custom_spacer * 5.5 ) + 'rem' );
$twelve: unquote( ( $custom_spacer * 6 ) + 'rem' );
$thirteen: unquote( ( $custom_spacer * 6.5 ) + 'rem' );
$fourteen: unquote( ( $custom_spacer * 7 ) + 'rem' );
$fifteen: unquote( ( $custom_spacer * 7.5 ) + 'rem' );

$spaces: ($zero, $one, $two, $three, $four, $five, $six, $seven, $eight, $nine, $ten, $eleven, $twelve, $thirteen, $fourteen, $fifteen);
// spaces-without-zero: Used for the negative margin mixin
$spaces-without-zero: ($one, $two, $three, $four, $five, $six, $seven, $eight, $nine, $ten, $eleven, $twelve, $thirteen, $fourteen, $fifteen);
$sides: (all, x, y, top, bottom, start, end);

@mixin generate-margin-padding($prefix, $spacing, $negative: false, $size-class: null) {
  @each $side in $sides {
    $i: if($negative, 1, 0);
    @each $space in $spacing {
      $letter: str-slice($prefix, 0, 1);
      $class: if($side == all, $letter, '#{$letter}#{str-slice($side, 0, 1)}');
      $value: if($negative, -#{$space}, #{$space});

      .#{$class}-#{if($size-class == null, '', '#{$size-class}-')}#{if($negative, 'n#{$i}', $i)} {
        @if $side == all {
          #{$prefix}: #{$value} !important;
        } @else if $side == 'x' {
          #{$prefix}-left: #{$value} !important;
          #{$prefix}-right: #{$value} !important;
        } @else if $side == 'y' {
          #{$prefix}-top: #{$value} !important;
          #{$prefix}-bottom: #{$value} !important;
        } @else {
          @if $side == 'start' {
            #{$prefix}-left: #{$value} !important;
          } @else if $side == 'end' {
            #{$prefix}-right: #{$value} !important;
          } @else {
            #{$prefix}-#{$side}: #{$value} !important;
          }
        }
      }
      $i: $i + 1;
    }
  }
}

// Creates m-#, mx-#, my-#, mt-#, mb-#, ms-#, me-#
@include generate-margin-padding('margin', $spaces);
.mt-auto { margin-top: auto !important; }
.mb-auto { margin-bottom: auto !important; }
.ms-auto { margin-left: auto !important; }
.me-auto { margin-right: auto !important; }
.mx-auto { margin-left: auto !important;  margin-right: auto !important; }
.my-auto { margin-top: auto !important;  margin-bottom: auto !important; }

// Creates m-n#, mx-n#, my-n#, mt-n#, mb-n#, ms-n#, me-n#
@include generate-margin-padding('margin', $spaces-without-zero, true);

// Creates p-#, px-#, py-#, pt-#, pb-#, ps-#, pe-#
@include generate-margin-padding('padding', $spaces);
.pt-auto { padding-top: auto !important; }
.pb-auto { padding-bottom: auto !important; }
.ps-auto { padding-left: auto !important; }
.pe-auto { padding-right: auto !important; }
.px-auto { padding-left: auto !important;  padding-right: auto !important; }
.py-auto { padding-top: auto !important;  padding-bottom: auto !important; }

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@each $breakpoint, $min-width in $breakpoints {
  @media (min-width: #{$min-width}) {
    @include generate-margin-padding('margin', $spaces, false, $breakpoint);
    .mt-#{$breakpoint}-auto { margin-top: auto !important; }
    .mb-#{$breakpoint}-auto { margin-bottom: auto !important; }
    .ms-#{$breakpoint}-auto { margin-left: auto !important; }
    .me-#{$breakpoint}-auto { margin-right: auto !important; }
    .mx-#{$breakpoint}-auto { margin-left: auto !important;  margin-right: auto !important; }
    .my-#{$breakpoint}-auto { margin-top: auto !important;  margin-bottom: auto !important; }

    @include generate-margin-padding('margin', $spaces-without-zero, true, $breakpoint);

    @include generate-margin-padding('padding', $spaces, false, $breakpoint);
    .pt-#{$breakpoint}-auto { padding-top: auto !important; }
    .pb-#{$breakpoint}-auto { padding-bottom: auto !important; }
    .ps-#{$breakpoint}-auto { padding-left: auto !important; }
    .pe-#{$breakpoint}-auto { padding-right: auto !important; }
    .px-#{$breakpoint}-auto { padding-left: auto !important;  padding-right: auto !important; }
    .py-#{$breakpoint}-auto { padding-top: auto !important;  padding-bottom: auto !important; }
  }
}
