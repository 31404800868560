//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change Around background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;
$primary: #0A66C2;
$secondary: #7283a0;
$font-weight-normal:                        300;
$font-weight-medium:                        400;
$font-weight-semibold:                      500;
$font-weight-bold:                          600;
$font-weight-bolder:                        700;

// Note: We can also add additional colors to the theme in the `_variables.scss` file — see the note
// about "custom Lucia colors" for more information.
